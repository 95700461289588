import React from 'react'
import styled from '@emotion/styled'
import Layout from '../../components/Layout'
import Scanner from '../../components/form/Scanner'
import Text from '../../components/common/Text'
import Space from '../../components/common/Space'
import SEO from '../../components/SEO'
import { Wrapper } from '../../components/pageSlices/ScannerBanner'

const StyledWrapper = styled.div`
  @media (min-width: 480px) {
    display: flex;
    min-height: calc(100vh - 325px);
    flex-direction: column;
    justify-content: center;
  }
`

export default function index() {
  return (
    <Layout hideHeaderFooterNav removeLogoLink showCTA={false} showSecondaryCTA={false} customSEO>
      <SEO
        title="AudioEye | Partner Scanner"
        pathname={typeof window !== 'undefined' ? window.location.pathname : null}
        noIndex
        noFollow
      />
      <StyledWrapper hasTopPadding hasBottomPadding>
        <Wrapper>
          <Text heading2 center>
            How Accessible Is Your Site?
          </Text>
          <Space height={32} />
          <Text center>Scan your website for free and see the accessibility violations that were found.</Text>
          <Space height={32} />
          <Scanner partner />
        </Wrapper>
      </StyledWrapper>
    </Layout>
  )
}
